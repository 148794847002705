import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "relative flex pt-14 pb-14 w-full justify-center items-center bg-transparent" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "container z-10" }
const _hoisted_4 = { class: "flex flex-col items-center justify-center mb-4" }
const _hoisted_5 = {
  key: 0,
  class: "uppercase block text-base font-medium"
}
const _hoisted_6 = { class: "mb-3 text-5xl font-bold" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "mb-4 px-4 text-xl font-extrabold uppercase text-primary flex items-center" }
const _hoisted_10 = {
  key: 0,
  class: "bg-amber-500 ml-2 text-white text-xs font-medium px-2.5 py-0.5 rounded"
}
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "flex justify-end" }
const _hoisted_13 = ["href", "onClick"]
const _hoisted_14 = {
  key: 0,
  class: "z-10 relative col-span-2 md:col-span-1 m-4 h-full flex justify-center items-center"
}
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "shadow-xl shadow-purple-100 z-10 hover:scale-110 transition-all duration-300 rounded-xl overflow-hidden max-w-[400px] max-h-[350px]" }
const _hoisted_17 = ["srcset", "src", "width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: require('@/assets/dot-grid.svg'),
      class: "absolute bottom-0 right-0",
      alt: "dot grid"
    }, null, 8, _hoisted_2),
    _createElementVNode("section", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.features.subTitle)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.features.subTitle), 1))
          : _createCommentVNode("", true),
        _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.features.title), 1),
        (_ctx.features.description)
          ? (_openBlock(), _createElementBlock("p", {
              key: 1,
              class: "description pt-4",
              innerHTML: _ctx.marked(_ctx.features.description)
            }, null, 8, _hoisted_7))
          : _createCommentVNode("", true)
      ]),
      (_ctx.features.blocks)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.features.blocks, (feature, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "w-full pt-10 pb-10 grid grid-cols-2 gap-x-4",
                key: feature.id
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["col-span-2 z-10 md:col-span-1 mx-2 h-full p-2 rounded-xl", { 'md:order-last': index % 2 !== 0 }])
                }, [
                  _createElementVNode("h3", _hoisted_9, [
                    _createTextVNode(_toDisplayString(feature.title) + " ", 1),
                    (feature.badge)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(feature.badge), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", {
                    class: "description px-4 py-2 text-wrap",
                    innerHTML: _ctx.marked(feature.description)
                  }, null, 8, _hoisted_11),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("a", {
                      href: `/${_ctx.currentLocale?.code}${feature.linkHref}`,
                      onClick: _withModifiers(($event: any) => (_ctx.$router.push(`/${_ctx.currentLocale?.code}${feature.linkHref}`)), ["prevent"]),
                      class: "feature-more"
                    }, [
                      _createTextVNode(_toDisplayString(feature.linkTitle) + " ", 1),
                      _createVNode(_component_fa_icon, {
                        class: "ml-1",
                        icon: "chevron-right"
                      })
                    ], 8, _hoisted_13)
                  ])
                ], 2),
                (feature.image)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      (index % 3 === 0)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: require('@/assets/triangles.svg'),
                            class: "absolute left-0 z-0",
                            alt: "triangles"
                          }, null, 8, _hoisted_15))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_16, [
                        (!!feature.image?.data)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              srcset: `${_ctx.strapiImageUrl(feature.image?.data?.attributes?.formats?.small?.url)} 500w, ${_ctx.strapiImageUrl(feature.image?.data?.attributes?.formats?.medium?.url)} 600w, ${_ctx.strapiImageUrl(feature.image?.data?.attributes?.formats?.large?.url)} 800w`,
                              sizes: "(max-width: 500px) 500px, (max-width: 600px) 480px, 800px",
                              src: _ctx.strapiImageUrl(feature.image?.data?.attributes?.formats?.large?.url),
                              alt: "hero",
                              width: feature.image?.data?.attributes?.formats?.large.width,
                              height: feature.image?.data?.attributes?.formats?.large.height,
                              class: "object-contain"
                            }, null, 8, _hoisted_17))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}