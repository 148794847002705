import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex bg-surface-ground align-center justify-center" }
const _hoisted_2 = {
  key: 0,
  class: "container px-4 text-xs text-stone-900 pb-6 pt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.global)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, " ©" + _toDisplayString(new Date().getFullYear()) + " " + _toDisplayString(_ctx.global.footerAllRightsReserved) + ". Fentrica ", 1))
      : _createCommentVNode("", true)
  ]))
}