import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex align-center justify-center" }
const _hoisted_2 = { class: "container my-10" }
const _hoisted_3 = { class: "px-4 sm:px-0 flex flex-col items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "text-9xl font-bold" }, "404", -1)),
        _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text-4xl mt-8" }, "Oops! Page not found", -1)),
        _cache[3] || (_cache[3] = _createElementVNode("p", { class: "text-xl mt-4" }, "The page you are looking for might have been removed or doesn't exist.", -1)),
        _createElementVNode("a", {
          href: "/",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.home && _ctx.home(...args)), ["prevent"])),
          class: "mt-8 inline-block text-white px-6 py-3 bg-primary-500 hover:bg-primary-500 rounded-lg text-lg"
        }, "Home")
      ])
    ])
  ]))
}