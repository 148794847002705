import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex relative pt-10 pb-10 w-full justify-center items-center bg-transparent" }
const _hoisted_2 = { class: "container px-4 z-10" }
const _hoisted_3 = { class: "flex items-center justify-center w-full mb-16" }
const _hoisted_4 = { class: "mb-3 text-5xl font-bold" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "w-full" }
const _hoisted_7 = {
  key: 0,
  class: "mb-3 mb-8 text-xl font-extrabold uppercase text-primary"
}
const _hoisted_8 = { class: "text-xs uppercase pt-1" }
const _hoisted_9 = { class: "pt-16 block" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = {
  key: 0,
  class: "mb-3 mb-8 text-xl font-extrabold uppercase text-primary"
}
const _hoisted_12 = { class: "shadow-xl shadow-purple-100 z-10 hover:scale-110 transition-all duration-300 rounded-xl overflow-hidden max-w-[600px] max-h-[500px]" }
const _hoisted_13 = ["srcset", "src", "alt", "width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.title ?? 'Real Results from Real Operators'), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.references, (reference, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["flex flex-wrap md:flex-nowrap gap-4 mb-20", { 'flex-row-reverse': index % 2 !== 0 }]),
            key: reference.id
          }, [
            _createElementVNode("div", _hoisted_6, [
              (index % 2 === 0)
                ? (_openBlock(), _createElementBlock("h2", _hoisted_7, _toDisplayString(reference.name), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass(["flex flex-wrap gap-3 items-center justify-center", { 'pt-16': index % 2 !== 0 }])
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(reference.functions, (fun) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: fun.id,
                    class: "flex items-center flex-col justify-center bg-surface-b p-1 text-center rounded-lg w-[120px] h-[100px]"
                  }, [
                    _createVNode(_component_fa_icon, {
                      icon: fun.faIcon,
                      class: "text-primary text-2xl items-center"
                    }, null, 8, ["icon"]),
                    _createElementVNode("div", _hoisted_8, _toDisplayString(fun.name), 1)
                  ]))
                }), 128))
              ], 2),
              _createElementVNode("p", _hoisted_9, _toDisplayString(reference.description), 1)
            ]),
            (reference.image)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  (index % 2 !== 0)
                    ? (_openBlock(), _createElementBlock("h2", _hoisted_11, _toDisplayString(reference.name), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("img", {
                      srcset: `${_ctx.strapiImageUrl(reference.image?.data?.attributes?.formats?.small?.url ?? reference.image?.data?.attributes?.formats?.small?.url)} 500w, ${_ctx.strapiImageUrl(reference.image?.data?.attributes?.formats?.medium?.url ?? reference.image?.data?.attributes?.formats?.small?.url)} 600w, ${_ctx.strapiImageUrl(reference.image?.data?.attributes?.formats?.large?.url ?? reference.image?.data?.attributes?.formats?.small?.url)} 800w`,
                      sizes: "(max-width: 500px) 500px, (max-width: 600px) 480px, 800px",
                      src: _ctx.strapiImageUrl(reference.image?.data?.attributes?.formats?.large?.url ?? reference.image?.data?.attributes?.formats?.small?.url),
                      alt: reference.name,
                      width: reference.image?.data?.attributes?.formats?.large?.width ?? reference.image?.data?.attributes?.formats?.small?.width,
                      height: reference.image?.data?.attributes?.formats?.large?.height ?? reference.image?.data?.attributes?.formats?.small?.height,
                      class: "object-contain"
                    }, null, 8, _hoisted_13)
                  ])
                ]))
              : _createCommentVNode("", true)
          ], 2))
        }), 128))
      ])
    ])
  ]))
}