import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "book",
  class: "relative py-10 my-4 flex items-center justify-center"
}
const _hoisted_2 = { class: "container px-4" }
const _hoisted_3 = { class: "flex flex-nowrap items-center justify-between gap-8" }
const _hoisted_4 = { class: "flex items-center gap-x-4" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "text-2xl font-semibold leading-[1.14] text-black" }
const _hoisted_7 = { class: "pt-2 text-base text-gray-600" }
const _hoisted_8 = {
  href: "https://cal.com/hendrik-lall/fentrica-demo",
  rel: "noopener noreferrer",
  target: "_blank",
  class: "book-btn"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeHero = _resolveComponent("HomeHero")!
  const _component_HomeClients = _resolveComponent("HomeClients")!
  const _component_HomeImpact = _resolveComponent("HomeImpact")!
  const _component_HomeKeyFeatures = _resolveComponent("HomeKeyFeatures")!
  const _component_HomeUseCases = _resolveComponent("HomeUseCases")!
  const _component_HomeReferences = _resolveComponent("HomeReferences")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_HomeHero, {
      hero: _ctx.home.hero
    }, null, 8, ["hero"]),
    (_ctx.home.clients)
      ? (_openBlock(), _createBlock(_component_HomeClients, {
          key: 0,
          title: _ctx.home.clientsTitle,
          clients: _ctx.home.clients
        }, null, 8, ["title", "clients"]))
      : _createCommentVNode("", true),
    (_ctx.home.impact)
      ? (_openBlock(), _createBlock(_component_HomeImpact, {
          key: 1,
          class: "mt-10",
          impact: _ctx.home.impact,
          title: _ctx.home.impactTitle
        }, null, 8, ["impact", "title"]))
      : _createCommentVNode("", true),
    (_ctx.home.features)
      ? (_openBlock(), _createBlock(_component_HomeKeyFeatures, {
          key: 2,
          class: "mt-10",
          features: _ctx.home.features
        }, null, 8, ["features"]))
      : _createCommentVNode("", true),
    (_ctx.home.useCases)
      ? (_openBlock(), _createBlock(_component_HomeUseCases, {
          key: 3,
          class: "mt-10",
          title: _ctx.home.useCasesTitle,
          subTitle: _ctx.home.useCasesSubTitle,
          cases: _ctx.home.useCases
        }, null, 8, ["title", "subTitle", "cases"]))
      : _createCommentVNode("", true),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              class: "hidden md:block",
              src: require('@/assets/dot-grid.svg'),
              alt: "dot grid"
            }, null, 8, _hoisted_5),
            _createElementVNode("div", null, [
              _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.global.bookTitle ?? 'Smarter Buildings Start Here'), 1),
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.global.bookText ?? 'Get a free 30-minute walkthrough of Fentrica’s platform. No pressure — just clarity.'), 1)
            ])
          ]),
          _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.global.bookButton ?? 'BOOK ONLINE HERE'), 1)
        ])
      ])
    ]),
    (_ctx.home.references?.length)
      ? (_openBlock(), _createBlock(_component_HomeReferences, {
          key: 4,
          class: "mt-10",
          references: _ctx.home.references,
          title: _ctx.home.referencesTitle
        }, null, 8, ["references", "title"]))
      : _createCommentVNode("", true)
  ]))
}