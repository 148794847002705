<!-- eslint-disable vue/max-len -->
<template>
  <div class="pt-4 pb-14">
    <div class="relative flex w-full justify-center items-center bg-transparent">
      <section class="container z-10">
        <h3 class="text-xs uppercase text-gray-500 font-bold mb-8">
          {{ title ?? 'Trusted by forward-thinking asset owners & operators' }}
        </h3>
      </section>
    </div>
    <div class="logos group z-10 relative overflow-hidden whitespace-nowrap [mask-image:_linear-gradient(to_right,_transparent_0,_white_128px,white_calc(100%-128px),_transparent_100%)]">
      <div class="animate-slide-left group-hover:animation-pause inline-block w-max">
        <a :href="client.link" target="_blank" rel="noopener noreferrer" v-for="(client, index) in clients" :key="index" class="group">
          <img
            class="transition-all grayscale hover:grayscale-0 duration-500 max-w-[200px] max-h-8 h-auto w-auto inline-block my-2 mx-3"
            :src="strapiImageUrl(client.logo?.data?.attributes?.formats?.thumbnail?.url ?? client.logo?.data?.attributes?.formats?.small?.url ?? client.logo?.data?.attributes?.formats?.medium?.url ?? client.logo?.data?.attributes?.formats?.large?.url ?? client.logo?.data?.attributes?.url)"
            :alt="client.name"
            :width="client.logo?.data?.attributes?.formats?.thumbnail?.width ?? client.logo?.data?.attributes?.formats?.small?.width ?? client.logo?.data?.attributes?.formats?.medium?.width ?? client.logo?.data?.attributes?.formats?.large?.width ?? client.logo?.data?.attributes?.width"
            :height="client.logo?.data?.attributes?.formats?.thumbnail?.height ?? client.logo?.data?.attributes?.formats?.small?.height ?? client.logo?.data?.attributes?.formats?.medium?.height ?? client.logo?.data?.attributes?.formats?.large?.height ?? client.logo?.data?.attributes?.height"
            :key="client.id"
          />
        </a>
      </div>
      <div class="animate-slide-left group-hover:animation-pause inline-block w-max" aria-hidden="true">
        <a :href="client.link" target="_blank" rel="noopener noreferrer" v-for="(client, index) in clients" :key="index" class="group">
          <img
            class="transition-all grayscale hover:grayscale-0 duration-500 max-w-[200px] max-h-8 h-auto w-auto inline-block my-2 mx-3"
            :src="strapiImageUrl(client.logo?.data?.attributes?.formats?.thumbnail?.url ?? client.logo?.data?.attributes?.formats?.small?.url ?? client.logo?.data?.attributes?.formats?.medium?.url ?? client.logo?.data?.attributes?.formats?.large?.url ?? client.logo?.data?.attributes?.url)"
            :alt="client.name"
            :width="client.logo?.data?.attributes?.formats?.thumbnail?.width ?? client.logo?.data?.attributes?.formats?.small?.width ?? client.logo?.data?.attributes?.formats?.medium?.width ?? client.logo?.data?.attributes?.formats?.large?.width ?? client.logo?.data?.attributes?.width"
            :height="client.logo?.data?.attributes?.formats?.thumbnail?.height ?? client.logo?.data?.attributes?.formats?.small?.height ?? client.logo?.data?.attributes?.formats?.medium?.height ?? client.logo?.data?.attributes?.formats?.large?.height ?? client.logo?.data?.attributes?.height"
            :key="client.id"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animation-pause {
    animation-play-state: paused;
  }
}

</style>
<script lang="ts">
import { Component, ComponentBase } from '@/component-base';
import GeneralConfig from '@/config/general';
import { strapiImageUrl } from '@/utils';

@Component({
  props: {
    clients: {
      type: Object,
    },
    title: {
      type: String,
    },
  },
})
export default class HomeClients extends ComponentBase {
  declare public title: string;

  declare public clients: any;

  public GeneralConfig = GeneralConfig;

  public strapiImageUrl = strapiImageUrl;

  public get currentLocale() {
    return this.AppState.currentLocale;
  }
}
</script>
