<template>
  <div v-if="!loading">
    <div class="page-title relative">
      <h1 class="mb-12 text-3xl font-bold leading-snug text-black sm:text-4xl sm:leading-snug lg:text-5xl lg:leading-[1.2]">
        <img :src="require('@/assets/dot-grid.svg')" class="absolute -top-[10px] left-0" alt="dot grid" />
        {{ title }}
      </h1>
    </div>
    <div class="page" v-if="component">
      <component :is="component" :id="resourceId" />
    </div>
  </div>
  <div v-else class="h-screen" />
</template>

<style lang="scss" scoped>
@tailwind components;
@layer components {
  .page-title {
    @apply relative flex justify-center items-center overflow-hidden pt-[60px] bg-surface-b
  }

}
</style>

<script lang="ts">
import { Component, ComponentBase } from '@/component-base';
import PageNotFound from './page-not-found.vue';
import Feature from './resources/feature.vue';
import Contact from './resources/contact.vue';
import Company from './resources/company.vue';
import Page from './resources/page.vue';

@Component({
  components: {
    PageNotFound,
    Feature,
    Contact,
    Company,
    Page,
  },
  watch: {
    $route() { // fetch data on each rote change.
      this.routeChange();
    },
  },
})
export default class Resource extends ComponentBase {
  public loading = true;

  public title = '';

  public resourceId = '';

  public flattenedNav: any[] = [];

  public component: string | null = null;

  public async created() {
    this.flattenedNav = this.flattenNav();
    this.routeChange();
  }

  public routeChange() {
    try {
      this.loading = true;
      const path = this.$route.fullPath;
      const [, lang, ...navPath] = path.split('/');
      this.checkLocale(lang);
      this.checkRoute(`/${navPath.join('/')}`);
    } catch (err) {
      this.title = '';
      this.component = 'PageNotFound';
    } finally {
      this.loading = false;
    }
  }

  private checkLocale(lang: string) {
    if (this.AppState.currentLocale?.code !== lang) {
      const locale = this.AppState.locales?.find((l) => l.code === lang);
      if (!locale) {
        throw new Error('Locale Not found');
      }
      this.AppState.setCurrentLocale(locale);
    }
  }

  private flattenNav() {
    const stack = [...this.AppState.nav, ...this.AppState.footNav];
    const flattened = [];

    while (stack.length) {
      const item = stack.pop();
      const newItem = { ...item };
      flattened.push(newItem);

      if (newItem.items && newItem.items.length > 0) {
        stack.push(...newItem.items.map((child: any) => ({ ...child })));
        delete newItem.items; // Remove nested items from the flattened structure
      }
    }
    return flattened;
  }

  // eslint-disable-next-line class-methods-use-this
  private checkRoute(path: string) {
    const item = this.flattenedNav.find((l) => l.path === path && l.type !== 'WRAPPER');
    // eslint-disable-next-line no-underscore-dangle
    const contentType = item?.related?.__contentType;
    if (!contentType) {
      throw new Error('Page Not found form navigation items');
    }
    this.title = item.title;
    this.resourceId = item.related.id;
    this.component = this.componentName(contentType);
  }

  // eslint-disable-next-line class-methods-use-this
  private componentName(contentType: string) {
    switch (contentType) {
      case 'api::feature.feature':
        return 'Feature';
      case 'api::company.company':
        return 'Company';
      case 'api::contact.contact':
        return 'Contact';
      case 'api::page.page':
        return 'Page';
      default:
        return 'PageNotFound';
    }
  }
}
</script>
