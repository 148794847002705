<!-- eslint-disable vue/max-len -->
<template>
  <footer class="relative flex align-center bg-surface-ground pt-8 justify-center">
    <div class="container border-t pt-8" v-if="global">
      <div class="flex flex-wrap">
        <div class="w-full px-4 sm:w-full md:w-3/12 lg:w-3/12 xl:w-3/12">
          <div class="mb-10 w-full">
            <a :href="`/${currentLocale?.code}/home`" @click.prevent="$router.push(`/${currentLocale?.code}/home`)" class="mb-6 inline-block max-w-[160px]">
              <img :src="require('@/assets/fentrica.svg')" alt="Fentrica" class="w-full max-w-full h-auto">
            </a>
            <p class="mb-8 max-w-[270px] text-xs text-gray-70">
              {{global.footerCompanySlogan}}
            </p>
          </div>
        </div>
        <div class="w-full px-4 sm:w-1/3 md:w-3/12 lg:w-3/12 xl:w-3/12" v-for="item of footNav" :key="item.id">
          <div class="mb-10 w-full">
            <h4 class="mb-4 text-lg font-semibold text-black">{{ item.title }}</h4>
            <ul>
              <li v-for="subItem of item.items" :key="subItem.id">
                <a
                  :href="subItem.type === 'EXTERNAL' ? subItem.path : `/${currentLocale?.code}${subItem.path}`"
                  @click.prevent="route(subItem.type === 'EXTERNAL' ? subItem.path : `/${currentLocale?.code}${subItem.path}`)"
                  :target="subItem.type === 'EXTERNAL' ? '_blank' : '_self'"
                  class="mb-3 inline-block text-base text-gray-7 hover:text-primary"
                >
                  {{subItem.title}}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { Component, ComponentBase } from '@/component-base';

@Component({
  components: {},
})
export default class FooterNav extends ComponentBase {
  public get currentLocale() {
    return this.AppState.currentLocale;
  }

  public get footNav() {
    return this.AppState.footNav;
  }

  public get global() {
    return this.AppState.global;
  }

  public async route(route: string) {
    if (route.startsWith('http://') || route.startsWith('https://')) {
      window.open(route, '_blank');
      return;
    }
    window.scrollTo({ top: 0, behavior: 'auto' });
    await this.$router.push(route);
  }
}
</script>
