import { createApp } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import Http from './http';

// main app
import App from './App.vue';
import Router from './router';
import Store from './store';

// @TODO: import all needed icons
library.add(fas, far, fab);

const app = createApp(App)
  .use(Store)
  .use(Router)
  .component('fa-icon', FontAwesomeIcon)
  .provide('http', new Http());

Router.isReady()
  .then(() => app.mount('#app'))
  .catch((err: Error) => {
    console.error('Error', err);
    // eslint-disable-next-line no-alert
    alert(err.message);
  });
