<template>
  <div class="flex relative pt-8 pb-8 w-full justify-center items-center bg-transparent">
    <img :src="require('@/assets/dot-grid.svg')" class="absolute -top-[60px] left-0" alt="dot grid" />
    <section class="container flex flex-wrap z-10">
      <div class="flex items-center justify-center w-full">
        <h2 class="mb-3 px-4 text-5xl font-bold">{{ title ?? 'Measurable Results from Day One' }}</h2>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full flex px-4 mt-10 md:w-1/2 lg:w-1/4" v-for="(item, index) of impact" :key="index">
          <div class="flex w-full flex-col items-center group bg-surface-b p-6 text-center rounded-lg">
            <div class="bg-primary relative rounded-full z-10 mb-6 flex h-[40px] w-[40px] items-center justify-center">
              <fa-icon :icon="item.faIcon" size="lg" class="text-white" />
            </div>
            <p class="mb-3 text-xl text-center font-bold">
              {{ item.title }} {{ item.subTitle }}
            </p>
            <p class="description text-lg text-center text-body-color" v-html="marked(item.description)" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { Component, ComponentBase } from '@/component-base';
import GeneralConfig from '@/config/general';

@Component({
  props: {
    impact: {
      type: Object,
    },
    title: {
      type: String,
    },
  },
})
export default class HomeImpact extends ComponentBase {
  declare public impact: any;

  declare public title: any;

  public GeneralConfig = GeneralConfig;
}
</script>
