import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "relative flex items-center justify-between top-0" }
const _hoisted_3 = { class: "w-60 max-w-full px-4" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "flex w-full items-center justify-between px-4" }
const _hoisted_7 = {
  ref: "nav",
  class: "navbar hidden lg:block"
}
const _hoisted_8 = { class: "flex items-end -mt-2 mr-2 justify-end lg:hidden" }
const _hoisted_9 = { class: "lg:flex" }
const _hoisted_10 = ["href", "onClick", "target"]
const _hoisted_11 = {
  key: 2,
  class: "submenu lg:hidden block mb-6"
}
const _hoisted_12 = { class: "submenu-content" }
const _hoisted_13 = ["href", "onClick"]
const _hoisted_14 = { class: "icon text-primary-300 group-hover/navitem:text-primary mx-4 flex flex-wrap justify-center align-center content-center" }
const _hoisted_15 = { class: "link w-full" }
const _hoisted_16 = { class: "text-primary" }
const _hoisted_17 = { class: "text-xs text-slate-600" }
const _hoisted_18 = { class: "flex flex-wrap justify-center align-center content-center" }
const _hoisted_19 = { class: "flex items-center justify-center lg:hidden p-4" }
const _hoisted_20 = {
  href: "https://app.fentrica.com",
  target: "_blank",
  rel: "noopener",
  class: "btn-login w-full"
}
const _hoisted_21 = { class: "hidden lg:block" }
const _hoisted_22 = { class: "hidden lg:block" }
const _hoisted_23 = {
  href: "https://app.fentrica.com",
  target: "_blank",
  rel: "noopener",
  class: "btn-login"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Locale = _resolveComponent("Locale")!
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "bg-yellow-500 text-black py-2 px-4 text-white w-full z-50 shadow-md" }, [
      _createElementVNode("div", { class: "container mx-auto text-center" }, [
        _createElementVNode("p", { class: "text-sm font-semibold" }, [
          _createTextVNode("☀️ Looking for Fentrica for Solar Parks? ☀️ "),
          _createElementVNode("a", {
            href: "https://fentrica.solar",
            class: "font-bold underline"
          }, "See here")
        ])
      ])
    ], -1)),
    (!_ctx.loading && _ctx.currentLocale && _ctx.global && _ctx.nav)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["menu py-2 sticky flex left-0 top-0 z-40 flex w-full justify-center items-center transition-all", { 'bg-surface-b': !_ctx.isHomePage || _ctx.isHomePage && _ctx.scrolledDown }])
        }, [
          _createElementVNode("section", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("a", {
                  href: `/${_ctx.currentLocale.code}/home`,
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.route(`/${_ctx.currentLocale.code}/home`)), ["prevent"])),
                  class: "block w-full py-5"
                }, [
                  _createElementVNode("img", {
                    src: require('@/assets/fentrica.svg'),
                    alt: "Fentrica Logo"
                  }, null, 8, _hoisted_5)
                ], 8, _hoisted_4)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", null, [
                  _createElementVNode("button", {
                    class: "navtoggle-btn",
                    "aria-label": "Navigation",
                    type: "button",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.toggleMenu()), ["prevent"]))
                  }, _cache[3] || (_cache[3] = [
                    _createElementVNode("span", { class: "navtoggle-stripe" }, null, -1),
                    _createElementVNode("span", { class: "navtoggle-stripe" }, null, -1),
                    _createElementVNode("span", { class: "navtoggle-stripe" }, null, -1)
                  ])),
                  _createElementVNode("nav", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_Locale)
                    ]),
                    _createElementVNode("ul", _hoisted_9, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nav, (item) => {
                        return (_openBlock(), _createElementBlock("li", {
                          class: "group relative",
                          key: item.id
                        }, [
                          (!item.items?.length)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                href: item.type === 'EXTERNAL' ? item.path : `/${_ctx.currentLocale?.code}${item.path}`,
                                onClick: _withModifiers(($event: any) => (_ctx.route(item.type === 'EXTERNAL' ? item.path : `/${_ctx.currentLocale?.code}${item.path}`)), ["prevent"]),
                                target: item.type === 'EXTERNAL' ? '_blank' : '_self',
                                class: _normalizeClass(["nav-item", { 'text-primary': _ctx.$route.path.startsWith(`/${_ctx.currentLocale?.code}${item.path}`) }])
                              }, _toDisplayString(item.title), 11, _hoisted_10))
                            : _createCommentVNode("", true),
                          (item.items?.length)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 1,
                                href: "#",
                                onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
                                class: "nav-item"
                              }, [
                                _createTextVNode(_toDisplayString(item.title) + " ", 1),
                                _createVNode(_component_fa_icon, {
                                  class: "ml-2 hidden lg:inline-block",
                                  icon: "chevron-down"
                                })
                              ]))
                            : _createCommentVNode("", true),
                          (item.items?.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                _createElementVNode("div", _hoisted_12, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.items, (subitem) => {
                                    return (_openBlock(), _createElementBlock("a", {
                                      class: _normalizeClass(["flex p-2 lg:p-4 group/navitem lg:rounded-lg cursor-pointer hover:bg-surface-50 hover:text-primary", { 'text-primary': _ctx.$route.path.startsWith(`/${_ctx.currentLocale?.code}${subitem.path}`) }]),
                                      href: subitem.type === 'EXTERNAL' ? subitem.path : `/${_ctx.currentLocale?.code}${subitem.path}`,
                                      onClick: _withModifiers(($event: any) => (_ctx.route(subitem.type === 'EXTERNAL' ? subitem.path : `/${_ctx.currentLocale?.code}${subitem.path}`)), ["prevent"]),
                                      key: subitem.id
                                    }, [
                                      _createElementVNode("div", _hoisted_14, [
                                        _createVNode(_component_fa_icon, {
                                          icon: subitem.faIcon,
                                          size: "xl"
                                        }, null, 8, ["icon"])
                                      ]),
                                      _createElementVNode("div", _hoisted_15, [
                                        _createElementVNode("div", _hoisted_16, [
                                          _createElementVNode("span", null, _toDisplayString(subitem.title), 1)
                                        ]),
                                        _createElementVNode("p", _hoisted_17, _toDisplayString(subitem.description), 1)
                                      ]),
                                      _createElementVNode("div", _hoisted_18, [
                                        _createVNode(_component_fa_icon, {
                                          class: "ml-2 invisible group-hover/navitem:visible inline-block",
                                          icon: "chevron-right"
                                        })
                                      ])
                                    ], 10, _hoisted_13))
                                  }), 128))
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("a", _hoisted_20, _toDisplayString(_ctx.global.loginButton), 1)
                    ])
                  ], 512)
                ])
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createVNode(_component_Locale, { class: "pr-2" })
              ]),
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("a", _hoisted_23, _toDisplayString(_ctx.global.loginButton), 1)
              ])
            ])
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}