<!-- eslint-disable vue/max-len -->
<template>
  <div class="pt-10 pb-14">
    <div class="relative flex flex-col w-full justify-center items-center bg-transparent">
      <section class="container z-10 flex flex-col items-center justify-center mb-16">
        <h3 class="mb-10 text-5xl font-bold">
          {{ title ?? 'Fentrica Works for Any Infrastructure. At Any Scale.' }}
        </h3>
        <p>
          {{ subTitle ?? 'If it uses energy, needs access control, or generates maintenance - we make it more efficient.' }}
        </p>
      </section>
      <section class="container">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div v-for="ca in cases" :key="ca.id" class="bg-surface-b rounded-2xl overflow-hidden">
            <div class="m-2 rounded-xl overflow-hidden h-[190px]">
              <img
                :srcset="`${strapiImageUrl(ca.image?.data?.attributes?.formats?.small?.url)} 500w, ${strapiImageUrl(ca.image?.data?.attributes?.formats?.medium?.url)} 600w, ${strapiImageUrl(ca.image?.data?.attributes?.formats?.large?.url)} 800w`"
                sizes="(max-width: 500px) 500px, (max-width: 600px) 480px, 800px"
                :src="strapiImageUrl(ca.image?.data?.attributes?.formats?.large?.url)"
                class="object-fill hover:scale-105 transition-all duration-300"
                :alt="ca.name"
                :key="ca.id"
              />
            </div>
            <div>
              <h3 class="text-xl text-center font-semibold mb-4 mt-4 px-4">{{ ca.name }}</h3>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, ComponentBase } from '@/component-base';
import GeneralConfig from '@/config/general';
import { strapiImageUrl } from '@/utils';

@Component({
  props: {
    cases: {
      type: Object,
    },
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
  },
})
export default class HomeUseCases extends ComponentBase {
  declare public title: string;

  declare public subTitle: string;

  declare public cases: any[];

  public GeneralConfig = GeneralConfig;

  public strapiImageUrl = strapiImageUrl;

  public get currentLocale() {
    return this.AppState.currentLocale;
  }
}
</script>
