import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative flex align-center bg-surface-ground pt-8 justify-center" }
const _hoisted_2 = {
  key: 0,
  class: "container border-t pt-8"
}
const _hoisted_3 = { class: "flex flex-wrap" }
const _hoisted_4 = { class: "w-full px-4 sm:w-full md:w-3/12 lg:w-3/12 xl:w-3/12" }
const _hoisted_5 = { class: "mb-10 w-full" }
const _hoisted_6 = ["href"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "mb-8 max-w-[270px] text-xs text-gray-70" }
const _hoisted_9 = { class: "mb-10 w-full" }
const _hoisted_10 = { class: "mb-4 text-lg font-semibold text-black" }
const _hoisted_11 = ["href", "onClick", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    (_ctx.global)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("a", {
                  href: `/${_ctx.currentLocale?.code}/home`,
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$router.push(`/${_ctx.currentLocale?.code}/home`)), ["prevent"])),
                  class: "mb-6 inline-block max-w-[160px]"
                }, [
                  _createElementVNode("img", {
                    src: require('@/assets/fentrica.svg'),
                    alt: "Fentrica",
                    class: "w-full max-w-full h-auto"
                  }, null, 8, _hoisted_7)
                ], 8, _hoisted_6),
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.global.footerCompanySlogan), 1)
              ])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.footNav, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "w-full px-4 sm:w-1/3 md:w-3/12 lg:w-3/12 xl:w-3/12",
                key: item.id
              }, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("h4", _hoisted_10, _toDisplayString(item.title), 1),
                  _createElementVNode("ul", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.items, (subItem) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: subItem.id
                      }, [
                        _createElementVNode("a", {
                          href: subItem.type === 'EXTERNAL' ? subItem.path : `/${_ctx.currentLocale?.code}${subItem.path}`,
                          onClick: _withModifiers(($event: any) => (_ctx.route(subItem.type === 'EXTERNAL' ? subItem.path : `/${_ctx.currentLocale?.code}${subItem.path}`)), ["prevent"]),
                          target: subItem.type === 'EXTERNAL' ? '_blank' : '_self',
                          class: "mb-3 inline-block text-base text-gray-7 hover:text-primary"
                        }, _toDisplayString(subItem.title), 9, _hoisted_11)
                      ]))
                    }), 128))
                  ])
                ])
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}