import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-4 pb-14" }
const _hoisted_2 = { class: "relative flex w-full justify-center items-center bg-transparent" }
const _hoisted_3 = { class: "container z-10" }
const _hoisted_4 = { class: "text-xs uppercase text-gray-500 font-bold mb-8" }
const _hoisted_5 = { class: "logos group z-10 relative overflow-hidden whitespace-nowrap [mask-image:_linear-gradient(to_right,_transparent_0,_white_128px,white_calc(100%-128px),_transparent_100%)]" }
const _hoisted_6 = { class: "animate-slide-left group-hover:animation-pause inline-block w-max" }
const _hoisted_7 = ["href"]
const _hoisted_8 = ["src", "alt", "width", "height"]
const _hoisted_9 = {
  class: "animate-slide-left group-hover:animation-pause inline-block w-max",
  "aria-hidden": "true"
}
const _hoisted_10 = ["href"]
const _hoisted_11 = ["src", "alt", "width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("section", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.title ?? 'Trusted by forward-thinking asset owners & operators'), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clients, (client, index) => {
          return (_openBlock(), _createElementBlock("a", {
            href: client.link,
            target: "_blank",
            rel: "noopener noreferrer",
            key: index,
            class: "group"
          }, [
            (_openBlock(), _createElementBlock("img", {
              class: "transition-all grayscale hover:grayscale-0 duration-500 max-w-[200px] max-h-8 h-auto w-auto inline-block my-2 mx-3",
              src: _ctx.strapiImageUrl(client.logo?.data?.attributes?.formats?.thumbnail?.url ?? client.logo?.data?.attributes?.formats?.small?.url ?? client.logo?.data?.attributes?.formats?.medium?.url ?? client.logo?.data?.attributes?.formats?.large?.url ?? client.logo?.data?.attributes?.url),
              alt: client.name,
              width: client.logo?.data?.attributes?.formats?.thumbnail?.width ?? client.logo?.data?.attributes?.formats?.small?.width ?? client.logo?.data?.attributes?.formats?.medium?.width ?? client.logo?.data?.attributes?.formats?.large?.width ?? client.logo?.data?.attributes?.width,
              height: client.logo?.data?.attributes?.formats?.thumbnail?.height ?? client.logo?.data?.attributes?.formats?.small?.height ?? client.logo?.data?.attributes?.formats?.medium?.height ?? client.logo?.data?.attributes?.formats?.large?.height ?? client.logo?.data?.attributes?.height,
              key: client.id
            }, null, 8, _hoisted_8))
          ], 8, _hoisted_7))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_9, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clients, (client, index) => {
          return (_openBlock(), _createElementBlock("a", {
            href: client.link,
            target: "_blank",
            rel: "noopener noreferrer",
            key: index,
            class: "group"
          }, [
            (_openBlock(), _createElementBlock("img", {
              class: "transition-all grayscale hover:grayscale-0 duration-500 max-w-[200px] max-h-8 h-auto w-auto inline-block my-2 mx-3",
              src: _ctx.strapiImageUrl(client.logo?.data?.attributes?.formats?.thumbnail?.url ?? client.logo?.data?.attributes?.formats?.small?.url ?? client.logo?.data?.attributes?.formats?.medium?.url ?? client.logo?.data?.attributes?.formats?.large?.url ?? client.logo?.data?.attributes?.url),
              alt: client.name,
              width: client.logo?.data?.attributes?.formats?.thumbnail?.width ?? client.logo?.data?.attributes?.formats?.small?.width ?? client.logo?.data?.attributes?.formats?.medium?.width ?? client.logo?.data?.attributes?.formats?.large?.width ?? client.logo?.data?.attributes?.width,
              height: client.logo?.data?.attributes?.formats?.thumbnail?.height ?? client.logo?.data?.attributes?.formats?.small?.height ?? client.logo?.data?.attributes?.formats?.medium?.height ?? client.logo?.data?.attributes?.formats?.large?.height ?? client.logo?.data?.attributes?.height,
              key: client.id
            }, null, 8, _hoisted_11))
          ], 8, _hoisted_10))
        }), 128))
      ])
    ])
  ]))
}