import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "contact",
  class: "relative pt-10 flex items-center justify-center"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "container px-4 z-10"
}
const _hoisted_4 = { class: "-mx-4 flex flex-wrap items-center" }
const _hoisted_5 = { class: "w-full px-4 lg:w-7/12 xl:w-8/12" }
const _hoisted_6 = { class: "ud-contact-content-wrapper" }
const _hoisted_7 = { class: "ud-contact-title mb-8 lg:mb-[120px]" }
const _hoisted_8 = { class: "mb-6 block text-base font-medium uppercase text-black" }
const _hoisted_9 = { class: "max-w-[260px] text-[35px] font-semibold leading-[1.14] text-black" }
const _hoisted_10 = { class: "mb-12 flex flex-wrap justify-between lg:mb-0" }
const _hoisted_11 = { class: "mb-8 flex w-[330px] max-w-full" }
const _hoisted_12 = { class: "mr-6 text-[32px] text-black lg:text-black" }
const _hoisted_13 = { class: "mb-[18px] text-lg font-semibold lg:text-black text-black" }
const _hoisted_14 = { class: "text-base lg:text-black text-black" }
const _hoisted_15 = { class: "mb-8 flex w-[330px] max-w-full" }
const _hoisted_16 = { class: "mr-6 text-[32px] text-black lg:text-black" }
const _hoisted_17 = { class: "mb-[18px] text-lg font-semibold lg:text-black text-black" }
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { class: "w-full px-4 lg:w-5/12 xl:w-4/12" }
const _hoisted_20 = {
  id: "footer-contact-header",
  class: "rounded-lg bg-white px-8 py-10 shadow-testimonial sm:px-10 sm:py-12 md:p-[60px] lg:p-10 lg:px-10 lg:py-12 2xl:p-[60px]"
}
const _hoisted_21 = { class: "mb-8 text-2xl font-semibold text-black md:text-[28px] md:leading-[1.42]" }
const _hoisted_22 = { class: "mb-[22px]" }
const _hoisted_23 = {
  for: "fullName",
  class: "mb-4 block text-sm text-body-color dark:text-dark-6"
}
const _hoisted_24 = ["disabled", "placeholder"]
const _hoisted_25 = { class: "mb-[22px]" }
const _hoisted_26 = {
  for: "email",
  class: "mb-4 block text-sm text-body-color dark:text-dark-6"
}
const _hoisted_27 = ["disabled", "placeholder"]
const _hoisted_28 = { class: "mb-[22px]" }
const _hoisted_29 = {
  for: "phone",
  class: "mb-4 block text-sm text-body-color dark:text-dark-6"
}
const _hoisted_30 = ["disabled", "placeholder"]
const _hoisted_31 = { class: "mb-[30px]" }
const _hoisted_32 = {
  for: "message",
  class: "mb-4 block text-sm text-body-color dark:text-dark-6"
}
const _hoisted_33 = ["disabled", "placeholder"]
const _hoisted_34 = { class: "mb-0" }
const _hoisted_35 = ["disabled"]
const _hoisted_36 = { key: 1 }
const _hoisted_37 = { class: "mb-8 text-xl font-semibold text-black text-center" }
const _hoisted_38 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("img", {
      src: require('@/assets/triangles.svg'),
      class: "absolute -left-[10%] z-0",
      alt: "triangles"
    }, null, 8, _hoisted_2),
    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "absolute left-0 top-0 -z-[1] h-full w-full" }, null, -1)),
    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "absolute left-0 top-0 -z-[1] h-1/2 w-full lg:h-[45%] xl:h-1/2" }, null, -1)),
    (_ctx.global)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.global.footerContactSectionSubTitle), 1),
                  _createElementVNode("h2", _hoisted_9, _toDisplayString(_ctx.global.footerContactSectionTitle), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_fa_icon, { icon: "location-dot" })
                    ]),
                    _createElementVNode("div", null, [
                      _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.global.footerLocationTitle), 1),
                      _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.global.footerLocation), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createVNode(_component_fa_icon, { icon: "envelope" })
                    ]),
                    _createElementVNode("div", null, [
                      _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.global.footerEmailTitle), 1),
                      _createElementVNode("div", {
                        class: "lg:text-black text-black",
                        innerHTML: _ctx.marked(_ctx.global.footerEmailContent)
                      }, null, 8, _hoisted_18)
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _cache[8] || (_cache[8] = _createElementVNode("a", { name: "footer-contact-a" }, null, -1)),
                _createElementVNode("h3", _hoisted_21, _toDisplayString(_ctx.global.footerFormTitle), 1),
                (!_ctx.success)
                  ? (_openBlock(), _createElementBlock("form", {
                      key: 0,
                      onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
                    }, [
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("label", _hoisted_23, [
                          _createTextVNode(_toDisplayString(_ctx.global.footerFormName) + " ", 1),
                          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "text-red-600" }, "*", -1))
                        ]),
                        _withDirectives(_createElementVNode("input", {
                          disabled: _ctx.loading,
                          type: "text",
                          name: "fullName",
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.name) = $event)),
                          placeholder: _ctx.global.footerFormNamePlaceholder,
                          class: "w-full border-0 border-b border-[#f1f1f1] bg-transparent pb-3 text-body-color placeholder:text-body-color/60 focus:border-primary focus:outline-none dark:border-dark-3 dark:text-dark-6"
                        }, null, 8, _hoisted_24), [
                          [_vModelText, _ctx.form.name]
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("label", _hoisted_26, [
                          _createTextVNode(_toDisplayString(_ctx.global.footerFormEmail) + " ", 1),
                          _cache[6] || (_cache[6] = _createElementVNode("span", { class: "text-red-600" }, "*", -1))
                        ]),
                        _withDirectives(_createElementVNode("input", {
                          disabled: _ctx.loading,
                          type: "email",
                          name: "email",
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.email) = $event)),
                          placeholder: _ctx.global.footerFormEmailPlaceholder,
                          class: "w-full border-0 border-b border-[#f1f1f1] bg-transparent pb-3 text-body-color placeholder:text-body-color/60 focus:border-primary focus:outline-none dark:border-dark-3 dark:text-dark-6"
                        }, null, 8, _hoisted_27), [
                          [_vModelText, _ctx.form.email]
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.global.footerFormPhone), 1),
                        _withDirectives(_createElementVNode("input", {
                          disabled: _ctx.loading,
                          type: "text",
                          name: "phone",
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.phone) = $event)),
                          placeholder: _ctx.global.footerFormPhonePlaceholder,
                          class: "w-full border-0 border-b border-[#f1f1f1] bg-transparent pb-3 text-body-color placeholder:text-body-color/60 focus:border-primary focus:outline-none dark:border-dark-3 dark:text-dark-6"
                        }, null, 8, _hoisted_30), [
                          [_vModelText, _ctx.form.phone]
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_31, [
                        _createElementVNode("label", _hoisted_32, [
                          _createTextVNode(_toDisplayString(_ctx.global.footerFormMessage) + " ", 1),
                          _cache[7] || (_cache[7] = _createElementVNode("span", { class: "text-red-600" }, "*", -1))
                        ]),
                        _withDirectives(_createElementVNode("textarea", {
                          disabled: _ctx.loading,
                          name: "message",
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.message) = $event)),
                          rows: "2",
                          placeholder: _ctx.global.footerFormMessagePlaceholder,
                          class: "w-full resize-none border-0 border-b border-[#f1f1f1] bg-transparent pb-3 text-body-color placeholder:text-body-color/60 focus:border-primary focus:outline-none dark:border-dark-3 dark:text-dark-6"
                        }, null, 8, _hoisted_33), [
                          [_vModelText, _ctx.form.message]
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_34, [
                        _createElementVNode("button", {
                          disabled: !_ctx.form.name || !_ctx.form.email || !_ctx.form.message || _ctx.loading,
                          type: "submit",
                          class: "disabled:cursor-not-allowed disabled:bg-primary-50 inline-flex items-center justify-center rounded-md bg-primary px-10 py-3 text-base font-medium text-white transition duration-300 ease-in-out hover:bg-primary-400"
                        }, [
                          (_ctx.loading)
                            ? (_openBlock(), _createBlock(_component_fa_icon, {
                                key: 0,
                                icon: "circle-notch",
                                class: "mr-1 fa-spin"
                              }))
                            : _createCommentVNode("", true),
                          _createTextVNode(" " + _toDisplayString(_ctx.global.footerFormSend), 1)
                        ], 8, _hoisted_35)
                      ])
                    ], 32))
                  : _createCommentVNode("", true),
                (_ctx.success)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                      _createElementVNode("h3", _hoisted_37, _toDisplayString(_ctx.global.footerFormSuccessTitle ?? 'Thank you!'), 1),
                      _createElementVNode("p", _hoisted_38, _toDisplayString(_ctx.global.footerFormSuccessContent ?? 'We will contact you shortly'), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}