import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center w-screen justify-center h-screen" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "max-w-sm text-center" }, [
      _createElementVNode("h1", { class: "mt-4 text-xl font-semibold text-gray-800" }, "Under Maintenance"),
      _createElementVNode("p", { class: "mt-2 text-gray-600" }, "We're currently performing scheduled maintenance. Sorry for the inconvenience. Please check back later. "),
      _createElementVNode("a", {
        href: "/",
        class: "mt-8 inline-block text-white px-6 py-3 bg-primary-500 hover:bg-primary-500 rounded-lg text-lg"
      }, "Reload")
    ], -1)
  ])))
}