<!-- eslint-disable vue/max-len -->
<template>
  <div>
    <HomeHero :hero="home.hero" />
    <HomeClients v-if="home.clients" :title="home.clientsTitle" :clients="home.clients" />
    <HomeImpact class="mt-10" v-if="home.impact" :impact="home.impact" :title="home.impactTitle" />
    <HomeKeyFeatures class="mt-10" v-if="home.features" :features="home.features" />
    <HomeUseCases class="mt-10" v-if="home.useCases" :title="home.useCasesTitle" :subTitle="home.useCasesSubTitle" :cases="home.useCases" />
    <section id="book" class="relative py-10 my-4 flex items-center justify-center">
      <div class="container px-4">
        <div class="flex flex-nowrap items-center justify-between gap-8">
          <div class="flex items-center gap-x-4">
            <img class="hidden md:block" :src="require('@/assets/dot-grid.svg')" alt="dot grid" />
            <div>
              <h2 class="text-2xl font-semibold leading-[1.14] text-black">
                {{global.bookTitle ?? 'Smarter Buildings Start Here' }}
              </h2>
              <p class="pt-2 text-base text-gray-600">{{ global.bookText ?? 'Get a free 30-minute walkthrough of Fentrica’s platform. No pressure — just clarity.' }}</p>
            </div>
          </div>
          <a href="https://cal.com/hendrik-lall/fentrica-demo" rel="noopener noreferrer" target="_blank" class="book-btn">
            {{ global.bookButton ?? 'BOOK ONLINE HERE' }}
          </a>
        </div>
      </div>
    </section>
    <HomeReferences class="mt-10" v-if="home.references?.length" :references="home.references" :title="home.referencesTitle" />
    <!-- <HomeIntegrations v-if="home.integrations" :integrations="home.integrations" /> -->
  </div>
</template>

<style lang="scss" scoped>
@tailwind components;

@layer components {
  .loading {
    @apply relative flex justify-center items-center overflow-hidden bg-gradient-to-r from-40% from-primary to-primary-300 pt-[120px]
  }
}
@layer components {
  .book-btn {
    @apply text-white inline-flex items-center justify-center rounded-xl bg-primary px-7 py-[14px] text-center text-base font-medium shadow hover:bg-primary-400
  }
}
</style>

<script lang="ts">
import { Component, ComponentBase } from '@/component-base';
import { strapiImageUrl } from '@/utils';
import HomeHero from './components/home-hero.vue';
import HomeImpact from './components/home-impact.vue';
import HomeIntegrations from './components/home-integrations.vue';
import HomeKeyFeatures from './components/home-key-features.vue';
import HomeReferences from './components/home-references.vue';
import HomeClients from './components/home-clients.vue';
import HomeUseCases from './components/home-use-cases.vue';

@Component({
  components: {
    HomeHero,
    HomeImpact,
    HomeKeyFeatures,
    HomeIntegrations,
    HomeReferences,
    HomeClients,
    HomeUseCases,
  },
  watch: {
    currentLocale() { // fetch data on each id change
      this.changed();
    },
  },
})
export default class Home extends ComponentBase {
  public loading = true;

  public home: any = {};

  public async created() {
    await this.changed();
  }

  public async changed() {
    try {
      this.loading = true;
      const { data: { data: { attributes: home } } } = await this.strapi.get('/homepage', {
        params: {
          locale: this.AppState.currentLocale?.code,
          populate: [
            'seo',
            'hero',
            'hero.image',
            'hero.bg',
            'hero.smallImage',
            'useCases',
            'useCases.image',
            'impact',
            'clientsTitle',
            'impactTitle',
            'clients',
            'clients.logo',
            'references',
            'references.image',
            'references.functions',
            'referencesTitle',
            'features',
            'features.blocks',
            'features.blocks.image',
            'integrations',
            'integrations.logosBusinessSoftware',
            'integrations.logosBuilding',
            'integrations.businessIntegrations',
            'integrations.buildingIntegrations',
          ],
        },
      });
      console.warn('home', home);
      this.home = home;
      this.setMetaInfo(home.seo?.metaTitle, home.seo?.metaDescription);
      if (home.hero?.image?.data?.attributes?.formats?.large?.url) {
        this.preloadImage(strapiImageUrl(home.hero?.image?.data?.attributes?.formats?.large?.url));
      }
      if (home.hero?.bg?.data?.attributes?.url) {
        this.preloadImage(strapiImageUrl(home.hero?.bg?.data?.attributes?.url));
      }
    } catch (err) {
    // @TODO: route to unexpected error
    } finally {
      document.querySelector('#app')?.classList.remove('loading');
      this.loading = false;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private preloadImage(url: string) {
    if (!url) {
      return;
    }
    if (document.querySelector(`head link[href="${url}"]`)) {
      return;
    }
    const head = document.querySelector('head');
    const link = document.createElement('link');
    link.fetchPriority = 'high';
    link.rel = 'preload';
    link.href = url;
    link.as = 'image';
    head?.appendChild(link);
  }

  public get currentLocale() {
    return this.AppState.currentLocale;
  }

  public get global() {
    return this.AppState.global;
  }
}
</script>
