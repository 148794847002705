import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-10 pb-14" }
const _hoisted_2 = { class: "relative flex flex-col w-full justify-center items-center bg-transparent" }
const _hoisted_3 = { class: "container z-10 flex flex-col items-center justify-center mb-16" }
const _hoisted_4 = { class: "mb-10 text-5xl font-bold" }
const _hoisted_5 = { class: "container" }
const _hoisted_6 = { class: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8" }
const _hoisted_7 = { class: "m-2 rounded-xl overflow-hidden h-[190px]" }
const _hoisted_8 = ["srcset", "src", "alt"]
const _hoisted_9 = { class: "text-xl text-center font-semibold mb-4 mt-4 px-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("section", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.title ?? 'Fentrica Works for Any Infrastructure. At Any Scale.'), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.subTitle ?? 'If it uses energy, needs access control, or generates maintenance - we make it more efficient.'), 1)
      ]),
      _createElementVNode("section", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cases, (ca) => {
            return (_openBlock(), _createElementBlock("div", {
              key: ca.id,
              class: "bg-surface-b rounded-2xl overflow-hidden"
            }, [
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(), _createElementBlock("img", {
                  srcset: `${_ctx.strapiImageUrl(ca.image?.data?.attributes?.formats?.small?.url)} 500w, ${_ctx.strapiImageUrl(ca.image?.data?.attributes?.formats?.medium?.url)} 600w, ${_ctx.strapiImageUrl(ca.image?.data?.attributes?.formats?.large?.url)} 800w`,
                  sizes: "(max-width: 500px) 500px, (max-width: 600px) 480px, 800px",
                  src: _ctx.strapiImageUrl(ca.image?.data?.attributes?.formats?.large?.url),
                  class: "object-fill hover:scale-105 transition-all duration-300",
                  alt: ca.name,
                  key: ca.id
                }, null, 8, _hoisted_8))
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("h3", _hoisted_9, _toDisplayString(ca.name), 1)
              ])
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}