import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex align-center justify-center bg-surface-b" }
const _hoisted_2 = {
  key: 0,
  class: "container markdown my-10"
}
const _hoisted_3 = { class: "flex flex-wrap justify-center" }
const _hoisted_4 = { class: "group mb-8 rounded-xl h-full bg-white px-5 pb-10 pt-12" }
const _hoisted_5 = { class: "relative z-10 mx-auto mb-5 h-[160px] w-[160px]" }
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = { class: "text-center" }
const _hoisted_8 = { class: "mb-1 text-lg font-semibold text-dark" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = {
  key: 1,
  class: "h-screen"
}
const _hoisted_11 = { class: "flex my-10 align-center justify-center gap-4 pb-20" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "max-w-[200px]" }
const _hoisted_14 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_Transition, {
        "enter-from-class": "opacity-0",
        "enter-active-class": "transition duration-700"
      }, {
        default: _withCtx(() => [
          (!_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "w-full mb-4 px-4 sm:w-1/2 lg:w-1/3 xl:w-1/3",
                      key: section.id
                    }, [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("img", {
                            src: _ctx.strapiImageUrl(section.image?.data?.attributes?.formats?.small?.url ?? section.image?.data?.attributes?.formats?.medium?.url ?? section.image?.data?.attributes?.formats?.large?.url),
                            alt: section.title,
                            class: "object-cover h-[160px] w-[160px] rounded-full"
                          }, null, 8, _hoisted_6)
                        ]),
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("h4", _hoisted_8, _toDisplayString(section.title), 1),
                          _createElementVNode("div", {
                            class: "markdown",
                            innerHTML: _ctx.marked(section.description)
                          }, null, 8, _hoisted_9)
                        ])
                      ])
                    ]))
                  }), 128))
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_10))
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", {
        class: "max-w-[250px] markdown text-right",
        innerHTML: _ctx.marked(_ctx.footerText)
      }, null, 8, _hoisted_12),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("img", {
          src: _ctx.strapiImageUrl(_ctx.footerImage?.data?.attributes?.formats?.small?.url ?? _ctx.footerImage?.data?.attributes?.formats?.medium?.url ?? _ctx.footerImage?.data?.attributes?.formats?.large?.url),
          alt: "Fentrica office",
          class: "object-cover mx-auto max-w-full rounded-full"
        }, null, 8, _hoisted_14)
      ])
    ])
  ]))
}