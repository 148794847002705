<template>
  <section class="hero z-10">
    <img :src="require('@/assets/dot-grid.svg')" class="absolute top-4 right-0 z-10" alt="dot grid" />
    <div v-if="hero?.bg?.data?.attributes?.formats?.large?.url" class="absolute -top-[50px] hidden lg:block -right-[400px] xl:-right-[200px] 2xl:-right-0 z-0 mx-auto max-w-[1000px]">
      <img
        :srcset="`${strapiImageUrl(hero.bg?.data?.attributes?.formats?.small?.url)} 500w, ${strapiImageUrl(hero.bg?.data?.attributes?.formats?.medium?.url)} 600w, ${strapiImageUrl(hero.bg?.data?.attributes?.formats?.large?.url)} 800w`"
        sizes="(max-width: 500px) 500px, (max-width: 600px) 480px, 800px"
        :src="strapiImageUrl(hero.bg?.data?.attributes?.formats?.large?.url)"
        alt="hero"
        :width="hero.bg?.data?.attributes?.formats?.large.width"
        :height="hero.bg?.data?.attributes?.formats?.large.height"
        class="mx-auto max-w-full rounded-t-xl rounded-tr-xl"
      >
    </div>
    <Transition enter-from-class="opacity-0" enter-active-class="transition duration-800">
      <div class="container z-10" v-if="hero">
        <div class="max-w-[700px] px-4">
          <p v-if="hero.subTitle" class="uppercase text-lg text-black mb-2">{{ hero.subTitle }}</p>
          <h1 class="hero-title" v-html="marked(hero.title)" />
          <p class="hero-description">
            {{ hero.description }}
          </p>
          <ul class="mb-10 flex flex-wrap items-center justify-start gap-5">
            <li>
              <a href="#footer-contact-a" @click.prevent="scrollToContact" class="hero-btn">
                {{ hero.ctaLabel }}
              </a>
              <a href="#footer-contact-b" @click.prevent="scrollToBook" class="hero-sub-btn">
                {{ hero.subCtaLabel ?? 'See It in Action' }}
              </a>
            </li>
          </ul>
        </div>
        <div class="w-full px-4" v-if="hero.image?.data?.attributes?.formats?.large?.url && !hero.bg?.data">
          <div class="relative z-10 mx-auto max-w-[800px]">
            <div class="mt-16">
              <img
                :srcset="`${strapiImageUrl(hero.image?.data?.attributes?.formats?.small?.url)} 500w, ${strapiImageUrl(hero.image?.data?.attributes?.formats?.medium?.url)} 600w, ${strapiImageUrl(hero.image?.data?.attributes?.formats?.large?.url)} 800w`"
                sizes="(max-width: 500px) 500px, (max-width: 600px) 480px, 800px"
                :src="strapiImageUrl(hero.image?.data?.attributes?.formats?.large?.url)"
                alt="hero"
                :width="hero.image?.data?.attributes?.formats?.large.width"
                :height="hero.image?.data?.attributes?.formats?.large.height"
                class="mx-auto max-w-full rounded-t-xl rounded-tr-xl"
              >
            </div>
            <div class="absolute border rounded-xl rounded-tr-xl overflow-hidden max-w-[240px] md:max-w-[320px] -right-[50px] -top-[20px] z-10" v-if="hero.smallImage?.data?.attributes?.formats?.small?.url">
              <img
                :srcset="`${strapiImageUrl(hero.smallImage?.data?.attributes?.formats?.thumbnail?.url)} 500w, ${strapiImageUrl(hero.smallImage?.data?.attributes?.formats?.large?.url)} 800w`"
                sizes="(max-width: 500px) 500px, 800px"
                :src="strapiImageUrl(hero.smallImage?.data?.attributes?.formats?.small?.url)"
                alt="hero-small"
                :width="hero.smallImage?.data?.attributes?.formats?.large.width"
                :height="hero.smallImage?.data?.attributes?.formats?.large.height"
                class="mx-auto max-w-full"
              >
            </div>
            <div class="absolute -left-9 bottom-0 z-[-1]">
              <img :src="require('@/assets/dots.svg')" alt="dots" />
            </div>
            <div class="absolute -right-6 -top-6 z-[-1]">
              <img :src="require('@/assets/dots.svg')" alt="dots" />
            </div>
          </div>
        </div>
      </div>
      <div v-else style="min-height: 400vh;" />
    </Transition>
  </section>
</template>

<style lang="scss" scoped>
@tailwind components;
@layer components {
  .hero {
    @apply relative flex justify-center items-center overflow-hidden bg-white pt-[50px]
  }
  .hero-title {
    @apply mb-6 text-3xl font-bold leading-snug text-black sm:text-5xl sm:leading-snug lg:text-6xl lg:leading-[1.2]
  }
  .hero-description {
    @apply mx-auto mb-9 text-lg font-medium text-black sm:leading-[1.44]
  }
  .hero-btn {
    @apply text-white inline-flex items-center justify-center rounded-xl bg-primary px-7 py-[14px] text-center text-lg font-medium shadow hover:bg-primary-400
  }
  .hero-sub-btn {
    @apply text-gray-800 hover:text-primary inline-flex items-center justify-center bg-transparent px-7 py-[14px] text-center text-lg font-medium
  }
}
</style>

<style lang="scss">
.hero-title em {
  color: var(--primary);
  font-style: normal;
}
</style>

<script lang="ts">
import { Component, ComponentBase } from '@/component-base';
import { strapiImageUrl } from '@/utils';

@Component({
  props: {
    hero: {
      type: Object,
    },
  },
})
export default class HomeHero extends ComponentBase {
  declare public hero: any;

  public strapiImageUrl = strapiImageUrl;

  // eslint-disable-next-line class-methods-use-this
  public scrollToContact() {
    const element = document.querySelector('#footer-contact-header');
    if (element) {
      const top = element.getBoundingClientRect().top + window.scrollY - 10;
      window.scroll({
        top,
        behavior: 'smooth',
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public scrollToBook() {
    const element = document.querySelector('#book');
    if (element) {
      const top = element.getBoundingClientRect().top + window.scrollY - 60;
      window.scroll({
        top,
        behavior: 'smooth',
      });
    }
  }
}
</script>
