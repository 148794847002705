<!-- eslint-disable vue/max-len -->
<template>
  <div class="relative flex pt-14 pb-14 w-full justify-center items-center bg-transparent">
    <img :src="require('@/assets/dot-grid.svg')" class="absolute bottom-0 right-0" alt="dot grid" />
    <section class="container z-10">
      <div class="flex flex-col items-center justify-center mb-4">
        <span v-if="features.subTitle" class="uppercase block text-base font-medium">{{ features.subTitle }}</span>
        <h2 class="mb-3 text-5xl font-bold">
          {{ features.title }}
        </h2>
        <p v-if="features.description" class="description pt-4" v-html="marked(features.description)" />
      </div>
      <div v-if="features.blocks">
        <div class="w-full pt-10 pb-10 grid grid-cols-2 gap-x-4" v-for="(feature, index) of features.blocks" :key="feature.id">
          <div class="col-span-2 z-10 md:col-span-1 mx-2 h-full p-2 rounded-xl" :class="{ 'md:order-last': index % 2 !== 0 }">
            <h3 class="mb-4 px-4 text-xl font-extrabold uppercase text-primary flex items-center">
              {{ feature.title }}
              <span v-if="feature.badge" class="bg-amber-500 ml-2 text-white text-xs font-medium px-2.5 py-0.5 rounded">{{feature.badge}}</span>
            </h3>
            <div class="description px-4 py-2 text-wrap" v-html="marked(feature.description)" />
            <div class="flex justify-end">
              <a :href="`/${currentLocale?.code}${feature.linkHref}`" @click.prevent="$router.push(`/${currentLocale?.code}${feature.linkHref}`)" class="feature-more">
                {{ feature.linkTitle }}
                <fa-icon class="ml-1" icon="chevron-right" />
              </a>
            </div>
          </div>
          <div v-if="feature.image" class="z-10 relative col-span-2 md:col-span-1 m-4 h-full flex justify-center items-center">
            <img :src="require('@/assets/triangles.svg')" class="absolute left-0 z-0" alt="triangles" v-if="index % 3 === 0" />
            <div class="shadow-xl shadow-purple-100 z-10 hover:scale-110 transition-all duration-300 rounded-xl overflow-hidden max-w-[400px] max-h-[350px]">
              <img
                v-if="!!feature.image?.data"
                :srcset="`${strapiImageUrl(feature.image?.data?.attributes?.formats?.small?.url)} 500w, ${strapiImageUrl(feature.image?.data?.attributes?.formats?.medium?.url)} 600w, ${strapiImageUrl(feature.image?.data?.attributes?.formats?.large?.url)} 800w`"
                sizes="(max-width: 500px) 500px, (max-width: 600px) 480px, 800px"
                :src="strapiImageUrl(feature.image?.data?.attributes?.formats?.large?.url)"
                alt="hero"
                :width="feature.image?.data?.attributes?.formats?.large.width"
                :height="feature.image?.data?.attributes?.formats?.large.height"
                class="object-contain"
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
// @TODO: data from prop
@tailwind components;
@layer components {
  .feature-more {
    @apply inline-flex items-center justify-end text-white px-3 py-1 text-right text-base font-medium text-primary hover:text-primary-400

  }
}
</style>

<script lang="ts">
import { Component, ComponentBase } from '@/component-base';
import GeneralConfig from '@/config/general';
import { strapiImageUrl } from '@/utils';

@Component({
  props: {
    features: {
      type: Object,
    },
  },
})
export default class HomeKeyFeatures extends ComponentBase {
  declare public features: any;

  public GeneralConfig = GeneralConfig;

  public strapiImageUrl = strapiImageUrl;

  public get currentLocale() {
    return this.AppState.currentLocale;
  }
}
</script>
