import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex relative pt-8 pb-8 w-full justify-center items-center bg-transparent" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "container flex flex-wrap z-10" }
const _hoisted_4 = { class: "flex items-center justify-center w-full" }
const _hoisted_5 = { class: "mb-3 px-4 text-5xl font-bold" }
const _hoisted_6 = { class: "flex flex-wrap" }
const _hoisted_7 = { class: "flex w-full flex-col items-center group bg-surface-b p-6 text-center rounded-lg" }
const _hoisted_8 = { class: "bg-primary relative rounded-full z-10 mb-6 flex h-[40px] w-[40px] items-center justify-center" }
const _hoisted_9 = { class: "mb-3 text-xl text-center font-bold" }
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: require('@/assets/dot-grid.svg'),
      class: "absolute -top-[60px] left-0",
      alt: "dot grid"
    }, null, 8, _hoisted_2),
    _createElementVNode("section", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.title ?? 'Measurable Results from Day One'), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.impact, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "w-full flex px-4 mt-10 md:w-1/2 lg:w-1/4",
            key: index
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_fa_icon, {
                  icon: item.faIcon,
                  size: "lg",
                  class: "text-white"
                }, null, 8, ["icon"])
              ]),
              _createElementVNode("p", _hoisted_9, _toDisplayString(item.title) + " " + _toDisplayString(item.subTitle), 1),
              _createElementVNode("p", {
                class: "description text-lg text-center text-body-color",
                innerHTML: _ctx.marked(item.description)
              }, null, 8, _hoisted_10)
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}