import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "hero z-10" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "absolute -top-[50px] hidden lg:block -right-[400px] xl:-right-[200px] 2xl:-right-0 z-0 mx-auto max-w-[1000px]"
}
const _hoisted_4 = ["srcset", "src", "width", "height"]
const _hoisted_5 = {
  key: 0,
  class: "container z-10"
}
const _hoisted_6 = { class: "max-w-[700px] px-4" }
const _hoisted_7 = {
  key: 0,
  class: "uppercase text-lg text-black mb-2"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "hero-description" }
const _hoisted_10 = { class: "mb-10 flex flex-wrap items-center justify-start gap-5" }
const _hoisted_11 = {
  key: 0,
  class: "w-full px-4"
}
const _hoisted_12 = { class: "relative z-10 mx-auto max-w-[800px]" }
const _hoisted_13 = { class: "mt-16" }
const _hoisted_14 = ["srcset", "src", "width", "height"]
const _hoisted_15 = {
  key: 0,
  class: "absolute border rounded-xl rounded-tr-xl overflow-hidden max-w-[240px] md:max-w-[320px] -right-[50px] -top-[20px] z-10"
}
const _hoisted_16 = ["srcset", "src", "width", "height"]
const _hoisted_17 = { class: "absolute -left-9 bottom-0 z-[-1]" }
const _hoisted_18 = ["src"]
const _hoisted_19 = { class: "absolute -right-6 -top-6 z-[-1]" }
const _hoisted_20 = ["src"]
const _hoisted_21 = {
  key: 1,
  style: {"min-height":"400vh"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("img", {
      src: require('@/assets/dot-grid.svg'),
      class: "absolute top-4 right-0 z-10",
      alt: "dot grid"
    }, null, 8, _hoisted_2),
    (_ctx.hero?.bg?.data?.attributes?.formats?.large?.url)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("img", {
            srcset: `${_ctx.strapiImageUrl(_ctx.hero.bg?.data?.attributes?.formats?.small?.url)} 500w, ${_ctx.strapiImageUrl(_ctx.hero.bg?.data?.attributes?.formats?.medium?.url)} 600w, ${_ctx.strapiImageUrl(_ctx.hero.bg?.data?.attributes?.formats?.large?.url)} 800w`,
            sizes: "(max-width: 500px) 500px, (max-width: 600px) 480px, 800px",
            src: _ctx.strapiImageUrl(_ctx.hero.bg?.data?.attributes?.formats?.large?.url),
            alt: "hero",
            width: _ctx.hero.bg?.data?.attributes?.formats?.large.width,
            height: _ctx.hero.bg?.data?.attributes?.formats?.large.height,
            class: "mx-auto max-w-full rounded-t-xl rounded-tr-xl"
          }, null, 8, _hoisted_4)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_Transition, {
      "enter-from-class": "opacity-0",
      "enter-active-class": "transition duration-800"
    }, {
      default: _withCtx(() => [
        (_ctx.hero)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                (_ctx.hero.subTitle)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.hero.subTitle), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("h1", {
                  class: "hero-title",
                  innerHTML: _ctx.marked(_ctx.hero.title)
                }, null, 8, _hoisted_8),
                _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.hero.description), 1),
                _createElementVNode("ul", _hoisted_10, [
                  _createElementVNode("li", null, [
                    _createElementVNode("a", {
                      href: "#footer-contact-a",
                      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.scrollToContact && _ctx.scrollToContact(...args)), ["prevent"])),
                      class: "hero-btn"
                    }, _toDisplayString(_ctx.hero.ctaLabel), 1),
                    _createElementVNode("a", {
                      href: "#footer-contact-b",
                      onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.scrollToBook && _ctx.scrollToBook(...args)), ["prevent"])),
                      class: "hero-sub-btn"
                    }, _toDisplayString(_ctx.hero.subCtaLabel ?? 'See It in Action'), 1)
                  ])
                ])
              ]),
              (_ctx.hero.image?.data?.attributes?.formats?.large?.url && !_ctx.hero.bg?.data)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("img", {
                          srcset: `${_ctx.strapiImageUrl(_ctx.hero.image?.data?.attributes?.formats?.small?.url)} 500w, ${_ctx.strapiImageUrl(_ctx.hero.image?.data?.attributes?.formats?.medium?.url)} 600w, ${_ctx.strapiImageUrl(_ctx.hero.image?.data?.attributes?.formats?.large?.url)} 800w`,
                          sizes: "(max-width: 500px) 500px, (max-width: 600px) 480px, 800px",
                          src: _ctx.strapiImageUrl(_ctx.hero.image?.data?.attributes?.formats?.large?.url),
                          alt: "hero",
                          width: _ctx.hero.image?.data?.attributes?.formats?.large.width,
                          height: _ctx.hero.image?.data?.attributes?.formats?.large.height,
                          class: "mx-auto max-w-full rounded-t-xl rounded-tr-xl"
                        }, null, 8, _hoisted_14)
                      ]),
                      (_ctx.hero.smallImage?.data?.attributes?.formats?.small?.url)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                            _createElementVNode("img", {
                              srcset: `${_ctx.strapiImageUrl(_ctx.hero.smallImage?.data?.attributes?.formats?.thumbnail?.url)} 500w, ${_ctx.strapiImageUrl(_ctx.hero.smallImage?.data?.attributes?.formats?.large?.url)} 800w`,
                              sizes: "(max-width: 500px) 500px, 800px",
                              src: _ctx.strapiImageUrl(_ctx.hero.smallImage?.data?.attributes?.formats?.small?.url),
                              alt: "hero-small",
                              width: _ctx.hero.smallImage?.data?.attributes?.formats?.large.width,
                              height: _ctx.hero.smallImage?.data?.attributes?.formats?.large.height,
                              class: "mx-auto max-w-full"
                            }, null, 8, _hoisted_16)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("img", {
                          src: require('@/assets/dots.svg'),
                          alt: "dots"
                        }, null, 8, _hoisted_18)
                      ]),
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("img", {
                          src: require('@/assets/dots.svg'),
                          alt: "dots"
                        }, null, 8, _hoisted_20)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_21))
      ]),
      _: 1
    })
  ]))
}