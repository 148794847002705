import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "page-title relative" }
const _hoisted_3 = { class: "mb-12 text-3xl font-bold leading-snug text-black sm:text-4xl sm:leading-snug lg:text-5xl lg:leading-[1.2]" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "page"
}
const _hoisted_6 = {
  key: 1,
  class: "h-screen"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, [
            _createElementVNode("img", {
              src: require('@/assets/dot-grid.svg'),
              class: "absolute -top-[10px] left-0",
              alt: "dot grid"
            }, null, 8, _hoisted_4),
            _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
          ])
        ]),
        (_ctx.component)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), { id: _ctx.resourceId }, null, 8, ["id"]))
            ]))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_6))
}