<!-- eslint-disable vue/max-len -->
<template>
  <div>
    <div class="flex align-center justify-center bg-surface-b">
      <Transition
        enter-from-class="opacity-0"
        enter-active-class="transition duration-700"
      >
        <div v-if="!loading" class="container markdown my-10">
          <div class="flex flex-wrap justify-center">
            <div class="w-full mb-4 px-4 sm:w-1/2 lg:w-1/3 xl:w-1/3" v-for="section of sections" :key="section.id">
              <div class="group mb-8 rounded-xl h-full bg-white px-5 pb-10 pt-12">
                <div class="relative z-10 mx-auto mb-5 h-[160px] w-[160px]">
                  <img :src="strapiImageUrl(section.image?.data?.attributes?.formats?.small?.url ?? section.image?.data?.attributes?.formats?.medium?.url ?? section.image?.data?.attributes?.formats?.large?.url)" :alt="section.title" class="object-cover h-[160px] w-[160px] rounded-full">
                </div>
                <div class="text-center">
                  <h4 class="mb-1 text-lg font-semibold text-dark">
                    {{ section.title }}
                  </h4>
                  <div class="markdown" v-html="marked(section.description)" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="h-screen" />
      </Transition>
    </div>
    <div class="flex my-10 align-center justify-center gap-4 pb-20">
      <div class="max-w-[250px] markdown text-right" v-html="marked(footerText)" />
      <div class="max-w-[200px]">
        <img :src="strapiImageUrl(footerImage?.data?.attributes?.formats?.small?.url ?? footerImage?.data?.attributes?.formats?.medium?.url ?? footerImage?.data?.attributes?.formats?.large?.url)" alt="Fentrica office" class="object-cover mx-auto max-w-full rounded-full">
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, ComponentBase } from '@/component-base';
import { strapiImageUrl } from '@/utils';

  @Component({
    props: {
      id: {
        required: true,
      },
    },
    components: {},
    watch: {
      id() { // fetch data on each id change
        this.changed();
      },
    },
  })
export default class Contact extends ComponentBase {
  declare public id: string;

  public loading = false;

  public footerText = '';

  public footerImage: any = null;

  public sections: any[] = [];

  public strapiImageUrl = strapiImageUrl;

  public created() {
    this.changed();
  }

  // eslint-disable-next-line class-methods-use-this
  public async changed() {
    try {
      this.loading = true;
      const { data: { data: { attributes } } } = await this.strapi.get<any>('/contact', {
        params: {
          locale: this.AppState.currentLocale?.code,
          populate: [
            'seo',
            'sections',
            'sections.image',
            'footerImage',
          ],
        },
      });
      this.sections = attributes.sections ?? [];
      this.footerText = attributes.footerText;
      this.footerImage = attributes.footerImage;

      this.setMetaInfo(attributes.seo?.metaTitle, attributes.seo?.metaDescription);
    } catch (err) {
      // @TODO: internal server error
      console.error(err);
    } finally {
      document.querySelector('#app')?.classList.remove('loading');
      this.loading = false;
    }
  }
}
</script>
