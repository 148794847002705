<!-- eslint-disable vue/max-len -->
<template>
  <div class="flex relative pt-10 pb-10 w-full justify-center items-center bg-transparent">
    <section class="container px-4 z-10">
      <div class="flex items-center justify-center w-full mb-16">
        <h2 class="mb-3 text-5xl font-bold">{{ title ?? 'Real Results from Real Operators' }}</h2>
      </div>
      <div class="">
        <div class="flex flex-wrap md:flex-nowrap gap-4 mb-20" :class="{ 'flex-row-reverse': index % 2 !== 0 }" v-for="(reference, index) in references" :key="reference.id">
          <div class="w-full">
            <h2 class="mb-3 mb-8 text-xl font-extrabold uppercase text-primary" v-if="index % 2 === 0">
              {{ reference.name }}
            </h2>
            <div class="flex flex-wrap gap-3 items-center justify-center" :class="{ 'pt-16': index % 2 !== 0 }">
              <div v-for="fun in reference.functions" :key="fun.id" class="flex items-center flex-col justify-center bg-surface-b p-1 text-center rounded-lg w-[120px] h-[100px]">
                <fa-icon :icon="fun.faIcon" class="text-primary text-2xl items-center" />
                <div class="text-xs uppercase pt-1">{{ fun.name }}</div>
              </div>
            </div>
            <p class="pt-16 block">
              {{ reference.description }}
            </p>
          </div>
          <div v-if="reference.image">
            <h2 class="mb-3 mb-8 text-xl font-extrabold uppercase text-primary" v-if="index % 2 !== 0">
              {{ reference.name }}
            </h2>
            <div class="shadow-xl shadow-purple-100 z-10 hover:scale-110 transition-all duration-300 rounded-xl overflow-hidden max-w-[600px] max-h-[500px]">
              <img
                :srcset="`${strapiImageUrl(reference.image?.data?.attributes?.formats?.small?.url ?? reference.image?.data?.attributes?.formats?.small?.url)} 500w, ${strapiImageUrl(reference.image?.data?.attributes?.formats?.medium?.url ?? reference.image?.data?.attributes?.formats?.small?.url)} 600w, ${strapiImageUrl(reference.image?.data?.attributes?.formats?.large?.url ?? reference.image?.data?.attributes?.formats?.small?.url)} 800w`"
                sizes="(max-width: 500px) 500px, (max-width: 600px) 480px, 800px"
                :src="strapiImageUrl(reference.image?.data?.attributes?.formats?.large?.url ?? reference.image?.data?.attributes?.formats?.small?.url)"
                :alt="reference.name"
                :width="reference.image?.data?.attributes?.formats?.large?.width ?? reference.image?.data?.attributes?.formats?.small?.width"
                :height="reference.image?.data?.attributes?.formats?.large?.height ?? reference.image?.data?.attributes?.formats?.small?.height"
                class="object-contain"
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { Component, ComponentBase } from '@/component-base';
import GeneralConfig from '@/config/general';
import { strapiImageUrl } from '@/utils';

@Component({
  props: {
    references: {
      type: Object,
    },
    title: {
      type: String,
    },
  },
})
export default class HomeImpact extends ComponentBase {
  declare public references: any;

  declare public title: any;

  public GeneralConfig = GeneralConfig;

  public strapiImageUrl = strapiImageUrl;
}
</script>
