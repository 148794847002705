import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex items-center justify-center bg-surface-ground pb-16 pt-8 w-full" }
const _hoisted_2 = { class: "container z-10" }
const _hoisted_3 = { class: "flex flex-wrap mt-4" }
const _hoisted_4 = { class: "info w-full lg:w-1/2 text-right flex flex-col justify-center" }
const _hoisted_5 = { class: "mb-4 text-3xl text-center font-bold sm:text-4xl" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  key: 0,
  class: "mt-10 md:mt-24 group flex flex-wrap justify-center align-center content-center"
}
const _hoisted_8 = ["src", "width", "height"]
const _hoisted_9 = ["src", "width", "height"]
const _hoisted_10 = { class: "w-full overflow-hidden mt-10 lg:mt-0 lg:w-1/2 icons-building flex flex-col items-center justify-center" }
const _hoisted_11 = { class: "top-integrations" }
const _hoisted_12 = { class: "text-xs uppercase" }
const _hoisted_13 = { class: "icon device-icon" }
const _hoisted_14 = { class: "text-xs uppercase" }
const _hoisted_15 = { class: "wrapper border-t border-dashed border-t-2 flex border-primary justify-center" }
const _hoisted_16 = { class: "icon" }
const _hoisted_17 = { class: "text-xs uppercase mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.integrations.title), 1),
          _createElementVNode("div", null, [
            _createElementVNode("p", {
              class: "text-base text-center text-body-color",
              innerHTML: _ctx.marked(_ctx.integrations.description)
            }, null, 8, _hoisted_6)
          ]),
          (_ctx.integrations.logosBusinessSoftware?.data)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.integrations.logosBusinessSoftware?.data, (logo) => {
                  return (_openBlock(), _createElementBlock("img", {
                    class: "transition-all duration-1000 max-w-22 max-h-5 grayscale group-hover:grayscale-0 h-auto w-auto inline-block my-2 mx-3",
                    src: _ctx.strapiImageUrl(logo.attributes?.formats?.thumbnail?.url ?? logo.attributes?.formats?.small?.url ?? logo.attributes?.formats?.medium?.url ?? logo.attributes?.formats?.large?.url ?? logo.attributes?.url),
                    alt: "Business Partner",
                    width: logo.attributes?.formats?.thumbnail?.width ?? logo.attributes?.formats?.small?.width ?? logo.attributes?.formats?.medium?.width ?? logo.attributes?.formats?.large?.width ?? logo.attributes?.width,
                    height: logo.attributes?.formats?.thumbnail?.height ?? logo.attributes?.formats?.small?.height ?? logo.attributes?.formats?.medium?.height ?? logo.attributes?.formats?.large?.height ?? logo.attributes?.height,
                    key: logo.id
                  }, null, 8, _hoisted_8))
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.integrations.logosBuilding?.data, (logo) => {
                  return (_openBlock(), _createElementBlock("img", {
                    class: "transition-all duration-1000 max-w-22 max-h-5 grayscale group-hover:grayscale-0 h-auto w-auto inline-block my-2 mx-3",
                    src: _ctx.strapiImageUrl(logo.attributes?.formats?.thumbnail?.url ?? logo.attributes?.formats?.small?.url ?? logo.attributes?.formats?.medium?.url ?? logo.attributes?.formats?.large?.url ?? logo.attributes?.url),
                    alt: "Business Partner",
                    width: logo.attributes?.formats?.thumbnail?.width ?? logo.attributes?.formats?.small?.width ?? logo.attributes?.formats?.medium?.width ?? logo.attributes?.formats?.large?.width ?? logo.attributes?.width,
                    height: logo.attributes?.formats?.thumbnail?.height ?? logo.attributes?.formats?.small?.height ?? logo.attributes?.formats?.medium?.height ?? logo.attributes?.formats?.large?.height ?? logo.attributes?.height,
                    key: logo.id
                  }, null, 8, _hoisted_9))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.integrations.businessIntegrations, (integration) => {
              return (_openBlock(), _createElementBlock("div", {
                key: integration.id,
                class: "bg-primary text-white rounded py-1 px-4 my-1 text-center"
              }, _toDisplayString(integration.title), 1))
            }), 128))
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "dots" }, null, -1)),
          _createVNode(_component_fa_icon, {
            size: "5x",
            icon: "cloud",
            class: "text-primary"
          }),
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.integrations.cloudTitle), 1),
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "dots" }, null, -1)),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_fa_icon, { icon: "microchip" })
          ]),
          _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.integrations.deviceTitle), 1),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "dots" }, null, -1)),
          _createElementVNode("div", _hoisted_15, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.integrations.buildingIntegrations, (integration) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "icon-container",
                key: integration.id
              }, [
                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "dots" }, null, -1)),
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_fa_icon, {
                    icon: integration.faIcon
                  }, null, 8, ["icon"])
                ])
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.integrations.buildingTechsysTitle), 1)
        ])
      ])
    ])
  ]))
}